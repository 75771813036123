.modal {
  /* Initially invisible */
  opacity: 0;
  transition: opacity 0.5s ease, visibility 0s ease 0.5s;
}

.modal-visible {
  /* Fully visible */
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease, visibility 0s ease 0.5s;
}

.backdrop {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*opacity: 0; /* Make it initially invisible */
  /*transition: opacity 0.5s ease, visibility 0s ease 0.5s; /* Transition the opacity */
  animation: fadeEffect 1s 0.5s forwards;
}

/*.modal-visible .backdrop {
  opacity: 1; /* Make it fully visible when modal is visible 
  transition: opacity 1s ease, visibility 0s ease 0.5s;
  
}*/

@keyframes fadeEffect {
  0% { backdrop-filter: blur(0px); }
  100% { backdrop-filter: blur(2px); }
}

.appHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 1000;

  width: 100%;
  height: 66px;
  background: #FFFFFF;

  border-bottom: 1px solid #E3E8EF;
}

.appTitle {
  position: sticky;
  text-align: center;
  left: 50%;        /* Align to the center of the screen horizontally */
  transform: translateX(-50%); /* Adjust horizontal position to truly center the element */
  cursor: pointer;
}

.feedbackLeft {
  position: sticky;
  left: 16px;
  cursor: pointer;
}

.loginButton {
  transform: translateX(-42px);/* translateY(5px);*/
  cursor: pointer;
}

/*.loginButton:hover {
  /*transform: translateX(-42px) translateY(5px);
}*/

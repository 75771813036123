.button {
  cursor: pointer;
  transition: all 0.5s;
}

.button:active {
  /*  Making button bigger on hover  */
  transform: scale(1.05) perspective(1px)
}

@media (hover: hover) and (pointer: fine) {
  .button:hover { transform: scale(1.05) perspective(1px); }
}
